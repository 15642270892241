// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
$(window).on('load', function () {
  if ($('.category-139').length) {
    if (window.location.href.indexOf('theme_test=true') > -1) {
      // remove loaded default.css
      $('link[href="//keypath.exeter.ac.uk/css/default.css"]').remove();
      // load theme_test CSS
      $('<link/>', {
        rel: 'stylesheet',
        type: 'text/css',
        href: 'https://keypath.exeter.ac.uk/theme_test/default.css',
      }).appendTo('head');

      // load theme_test JS
      $.getScript('https://keypath.exeter.ac.uk/theme_test/kp.js', function () {
        console.warn('Warning: Currently using development theme files.');
      });
    } else {
      $.getScript('https://keypath.exeter.ac.uk/js/keypath/kp.js');
    }
  }
});
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
// DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE -- DO NOT EDIT THIS FILE
